<template>
  <div class="content block-el">
    <PageHeader :title="$t('sms.short-code.add')" />
    <div class="page-content container-fluid">
      <FormComponent ref="store-shortcode" class="register" @submit="create">
        <div class="row">
          <div class="col-12">
            <CardComponent variant="tertiary">
              <template #header>
                {{ $tc('app.general') }}
              </template>
              <div class="row">
                <div class="col-3">
                  <InputComponent v-model="form.name" :label="$tc('app.name', 1)" required type="text"
                    :placeholder="$tc('app.name', 1)" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.shortcode" label="Shortcode" required type="text"
                    placeholder="Shortcode" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.deliverability" :label="$tc('app.deliverability', 1)" required
                    type="tel" :rules="['integer', 'min-numeric:0', 'max-numeric:100']"
                    :placeholder="$tc('app.deliverability', 1)">
                    <template #append>
                      <span class="input-group-text">
                        <span class="material-symbols-outlined">
                          percent
                        </span>
                      </span>
                    </template>
                  </InputComponent>
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.priority" :label="$tc('app.priority', 1)" required type="text"
                    :rules="['integer', 'min-numeric:0', 'max-numeric:3']" :placeholder="$tc('app.priority', 1)" />
                </div>
              </div>
            </CardComponent>
          </div>
          <div class="col-12">
            <CardComponent variant="tertiary">
              <template #header>
                {{ $tc('app.rule', 2) }}
              </template>
              <div class="row" v-for="(rule, n) in form.rules" :key="n">
                <div class="col-10">
                  <CardComponent>
                    <h4 class="card-title">{{ rule.name }}</h4>
                    <div v-for="(provider, i) in rule.providers" :key="i" class="row">
                      <div class="col-12 col-sm-2">
                        <InputComponent type="tel" v-model="provider.deliverability"
                          :label="$tc('app.deliverability', 1)" :rules="['integer', 'min-numeric:0', 'max-numeric:100']"
                          required>
                          <template #append>
                            <span class="input-group-text">
                              <span class="material-symbols-outlined">
                                percent
                              </span>
                            </span>
                          </template>
                        </InputComponent>
                      </div>
                      <div class="col-12 col-sm-3">
                        <InputComponent type="tel" v-model="provider.shortcode" label="Shortcode"
                          placeholder="Shortcode" required />
                      </div>
                      <div class="col-12 col-sm-2">
                        <InputComponent type="tel" v-model="provider.share" label="Distribuição"
                          placeholder="Distribuição" :rules="['integer', 'min-numeric:0', 'max-numeric:100']" required>
                          <template #append>
                            <span class="input-group-text">
                              <span class="material-symbols-outlined">
                                percent
                              </span>
                            </span>
                          </template>
                        </InputComponent>
                      </div>
                      <div class="col-12 col-sm-3">
                        <SelectComponent v-model="provider.provider_id" :options="providers"
                          :label="$tc('app.provider', 1)" textField='name' valueField="id" required />
                      </div>
                      <div class="col-12 col-sm-1 pt-2 mt-4 d-flex">
                        <ButtonComponent variant="primary" @click="addProvider(rule)">
                          <span class="material-symbols-outlined">
                            add
                          </span>
                        </ButtonComponent>
                        <ButtonComponent v-if="rule.providers.length > 1" variant="primary" class="ml-2"
                          @click="removeProvider(rule, i)">
                          <span class="material-symbols-outlined">
                            delete
                          </span>
                        </ButtonComponent>
                      </div>
                    </div>
                  </CardComponent>
                </div>
                <div class="col-2">
                  <CardComponent>
                    <h1 class="card-title">{{ calcRuleCost(rule) | currency4D }}</h1>
                  </CardComponent>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ButtonComponent variant="secondary" class="float-right ml-2"
                    @click="$refs['store-shortcode'].submit()">
                    <span class="material-symbols-outlined">
                      save
                    </span>
                  </ButtonComponent>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </FormComponent>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/admin/sms.service';
import ProviderService from '@/services/admin/provider.service';
import CardComponent from '@/components/CardComponent.vue';
import FormComponent from '@/components/form/FormComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'CreateProduct',
  components: {
    PageHeader,
    CardComponent,
    FormComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fetched: false,
      carriers: [
        {
          text: this.$tc('app.all', 2),
          value: 'all',
        },
        {
          text: 'Vivo',
          value: 'vivo',
        },
        {
          text: 'Tim',
          value: 'tim',
        },
        {
          text: 'Claro',
          value: 'claro',
        },
        {
          text: this.$tc('app.other', 2),
          value: 'other',
        },
      ],
      defaultRules: {
        deliverability: 100,
        shortcode: '',
        share: 100,
      },
      providers: [],
      form: {
        name: '',
        priority: 1,
        deliverability: 100,
        rules: {
          tim: {
            name: 'Tim',
            carrier: 'tim',
            providers: [
              {
                deliverability: 100,
                shortcode: '',
                share: 100,
              },
            ],
          },
          vivo: {
            name: 'Vivo',
            carrier: 'vivo',
            providers: [
              {
                deliverability: 100,
                shortcode: '',
                share: 100,
              },
            ],
          },
          claro: {
            name: 'Claro',
            carrier: 'claro',
            providers: [
              {
                deliverability: 100,
                shortcode: '',
                share: 100,
              },
            ],
          },
          other: {
            name: this.$tc('app.other', 2),
            carrier: 'other',
            providers: [
              {
                deliverability: 100,
                shortcode: '',
                share: 100,
              },
            ],
          },
        },
      },
      creating: false,
      pages: 1,
    };
  },
  created() {
    this.fetchProviders();
  },
  methods: {
    fetchProviders() {
      this.fetched = false;
      ProviderService.getProviders({ sms: true }).then(
        (providers) => {
          this.providers = providers;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    addRule() {
      this.form.rules.push({ ...this.defaultRules });
    },
    removeRule(i) {
      this.form.rules.splice(i, 1);
    },
    addProvider(rule) {
      rule.providers.push({ ...this.defaultRules });
    },
    removeProvider(rule, i) {
      rule.providers.splice(i, 1);
    },
    calcRuleCost(rule) {
      let cost = 0;
      let share = 0;
      rule.providers.forEach((provider) => {
        const providerData = this.providers.filter((pr) => pr.id === provider.provider_id);
        share += provider.share;
        if (providerData.length) {
          cost += ((providerData[0].values.sms[rule.carrier] * provider.deliverability) / 100) * (provider.share / 100);
        }
      });
      return cost;
    },
    create() {
      this.creating = true;
      SmsService.createShortCode(this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$tc('short-code.created'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (response) => {
            response.errors.forEach((error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.status}`,
                content: error.detail,
                type: 'danger',
              });
            });
          },
        )
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
